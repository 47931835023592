import React, { useEffect, useState } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Layout, Menu, ConfigProvider  } from 'antd';
import { ThunderboltOutlined, FireOutlined, BarChartOutlined, LogoutOutlined } from '@ant-design/icons';
import PowerBiEmbedded from './PowerBiEmbedded';
import { UserStatus } from './utils/userStatus.js'
import useInterval from "use-interval";
import Logo from "./logo.svg"

const { Sider, Content } = Layout;

function Home() {
  const { logout, getAccessTokenSilently } = useAuth0();

  const [status, setStatus] = useState(UserStatus.LOADING);
  const [embedInfo, setEmbedInfo] = useState({reportPages: []}); // other props needed for embedding, reportpages set to display
  const [selected, setSelected] = useState("ReportSection");

  // Embed the report on initial load
  useEffect(()=> embed(), []);
  async function embed() {
		const token = await getAccessTokenSilently();
    
		const r = await fetch('/api/getembedinfo', {
			headers: {
				Authorization: `Bearer ${token}`,
				Initial: isInitialRequest,
			},
		});
		let response = await r.json();

    // Hide drillthrough pages
    const to_hide = ["Electricity - Facility", "Electricity - Interval"]
    response.reportPages = response.reportPages.filter(page => !to_hide.includes(page.displayName))

    setEmbedInfo(response)				
	}


  // Check if the server is online, repeat every 15 seconds, embed the report if it is
  let isInitialRequest = true
  useEffect(()=> checkCapacity(), []);
  useInterval(() => checkCapacity(), (status !==  UserStatus.READY) ? 15000 : null)
  async function checkCapacity() {
		const token = await getAccessTokenSilently();

    const r = await fetch('/api/getcapacityinfo', {
			headers: {
				Authorization: `Bearer ${token}`,
				Initial: isInitialRequest,
			},
		});
		isInitialRequest = false

		let response = await r.json();
    const isServerOnline = response?.is_online

    // If the server is online and we are still loading, then embed the report
		if ( isServerOnline && status === UserStatus.LOADING_POWERBI) {
      embed()
		}
    isServerOnline ? setStatus(UserStatus.READY) : setStatus(UserStatus.LOADING_POWERBI)

  }

  const pages = embedInfo.reportPages.map(page => ({
    group: page.displayName.split(" - ")[0],
    label: page.displayName.split(" - ")[1],
    key: page.name,
  }));

  const menu_items = [{
    key: "sub1",
    label: "Electricity",
    icon: <ThunderboltOutlined />,
    children: pages.filter(page => page.group === "Electricity")
  },
  {
    key: "sub2",
    label: "Natural Gas",
    icon: <FireOutlined />,
    children: pages.filter(page => page.group === "Natural Gas")
  },
  {
    key: "sub3",
    label: "Reports",
    icon: <BarChartOutlined />,
    children: pages.filter(page => page.group === "Reports")
  }];


  return (
    <ConfigProvider
      theme = {{
        components: {
          Menu: {
            itemSelectedBg: "rgba(0, 0, 0, 0.06)"
          }
        }
      }}
    >
    <Layout hasSider>
      <Sider
         theme="light" 
         style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          borderRight: "1px solid #e8e8e8"
        }}
      >
        <div className="logo"
          style = {{
            textAlign: 'center',
            height: '32px',
            margin: '31px 34px',
          }}
        >
          <img src={Logo} alt="wattsworth"/>
        </div>

        <Menu 
          mode="inline" 
          items={menu_items} 
          defaultOpenKeys={['sub1']} 
          defaultSelectedKeys='ReportSection'
          onClick={(a) => setSelected(a.key)}
          style={{
            borderRight: 0,
          }}
        />

        <Menu
          mode="inline"
          items={[{
              label: 'Logout',
              icon: < LogoutOutlined />,
            }]}
            style={{
              position: 'absolute',
              bottom: 0,
              zIndex: 1,
              transition: 'all 0.2s',
              borderRight: 0,
          }}
          onClick={() => logout({ returnTo: window.location.origin })}
        />



      </Sider>
      <Layout
        style={{ marginLeft: 200, height: '100vh' }}
      >
        <Content
          style={{
            margin: 16,
            padding: 16,
            background: '#fff',
            borderRadius: 5,
          }}
        >

        {status === UserStatus.LOADING && <div><h3>Loading...</h3></div> }
        {status === UserStatus.LOADING_POWERBI && <div><h3>Starting Capacity...</h3><p>Server capacity is reduced during off-peak hours, which could result in slower initial response time. This process may take a few minutes. We appreciate your understanding as we continuously improve our service.</p></div> }

        <PowerBiEmbedded apiMessage={embedInfo} selected={selected} status={status} style={{height: '100vh'}}/>

        </Content>
      </Layout>
    </Layout>
    </ConfigProvider>
  );
  
}


export default withAuthenticationRequired(Home);


