import React from 'react';
import { ConfigProvider } from 'antd';


import Home from './Home';
import { useAuth0 } from "@auth0/auth0-react";

const config = {
  token: {
    colorPrimary: '#184E77',
    colorBgLayout: '#FFFFFF'
  }
}


function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect } =
    useAuth0();

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
    <ConfigProvider theme={config}><Home /></ConfigProvider>
    )
  } else {
    loginWithRedirect();
  }
}

export default App;