import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
// import './index.css';


const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
  <Auth0Provider
    domain="dev-u4y37ets.auth0.com"
    clientId="ixckLfKUBl2AdIWmXHWZKY7TF6pmBzJe"
    redirectUri={window.location.origin}
    audience="https://energy.wattsworth.com"
  >
    <App />
  </Auth0Provider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
