import React from "react";
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { UserStatus } from './utils/userStatus.js'

import './powerbifix.css'

export const PowerBiEmbedded = (props) => {
    if (props.status === UserStatus.READY) {
        return (
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',
                    id: props.apiMessage.reportConfig[0].reportId,
                    embedUrl: props.apiMessage.reportConfig[0].embedUrl,
                    accessToken: props.apiMessage.accessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: true,
                            },
                            pageNavigation: {
                                visible: false,
                            }
                        }
                    },
                    pageName: props.selected,
                    datasetBinding: {
                        datasetId: props.apiMessage.reportConfig[0].dataset, // The dataset id that you want the report to use 
                    },
                }}
                style = {{
                    height: '100vh',
                    width: '100%'
                }}
                cssClassName = { "report-style-class" }
            />
        );
    } else  if (props.status === UserStatus.LOADING || props.status === UserStatus.LOADING_POWERBI) {
        // bootstrap
        return (
            <div style={{ visibility: "hidden", height: '100vh', width: '100%' }}>
            <PowerBIEmbed
            embedConfig = {{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: undefined, 
                embedUrl: undefined,
                accessToken: undefined,    // Keep as empty string, null or undefined
                tokenType: models.TokenType.Embed,
            }}
            />
            </div>
        )
    } else {
        return (<></>);
    }
}

export default PowerBiEmbedded;

